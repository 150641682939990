import React from 'react'
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <div>
    <Layout title="404">
      <div className="center-absolute">
        <div>
          404 - This page does not (yet?) exist.
        </div>
      </div>
    </Layout>
  </div>
)

export default NotFoundPage
